import Vue from "vue"
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
    components: {}
})
export default class Naming extends Vue {

    @Prop()
    value: { name: string }

}