import { store } from "@/store";
import Vue from "vue";
import Component from "vue-class-component";

@Component({})
export default class Sidebar extends Vue {
  
  expanded: boolean = false;

  get urlDoc(){
    return "ManualeApplicativo.pdf";
  }

  expand() {
    this.expanded = !this.expanded
  }

  goTo(path: string) {
    if (this.$router.currentRoute.fullPath != path)
      this.$router.push(path)
  }
};


