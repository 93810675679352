import { metadataServices } from '@/services/metadata.services';
import { store } from '@/store';
import Vue from 'vue'
import Component from 'vue-class-component';
import loadMore from "@/components/loadMore/loadMore.vue";
import { ModalHelper } from '@/modals/ModalHelper';
import DatePicker from 'vue2-datepicker';

@Component({
    components: {
        loadMore,
        DatePicker
    }
})
export default class DownloadExcel extends Vue {

    selectedAll: boolean = false
    searchName: string = null
    elaborationFromDate: Date = null
    elaborationToDate: Date = null
    selectedLines: string[] = []
    loading = false;

    get linesList() {
        return store.getters.domain.getLines().filter(line => {
            let composedName = line.strno + " \\ " + line.pltxt
            return this.searchName == null || composedName.toLowerCase().includes(this.searchName.toLowerCase())
        }
        );
    }

    get dataElaborazione(): Date[] {
        return [
            this.elaborationFromDate != null ? new Date(this.elaborationFromDate) : null,
            this.elaborationToDate != null ? new Date(this.elaborationToDate) : null
        ]
    }
    set dataElaborazione(value: Date[]) {
        this.elaborationFromDate = value[0] == null ? null : new Date(value[0].getFullYear(), value[0].getMonth(), value[0].getDate(), 12, 0, 0)
        this.elaborationToDate = value[1] == null ? null : new Date(value[1].getFullYear(), value[1].getMonth(), value[1].getDate(), 12, 0, 0)
    }

    selectLine(strno: string) {
        var index = this.selectedLines.findIndex(selStrno => selStrno == strno)
        if (index > -1) this.selectedLines.splice(index, 1)
        else this.selectedLines.push(strno)
    }

    selectAll(all: boolean) {
        this.selectedAll = all
        if (all) this.selectedLines = this.linesList.map(line => line.strno)
        else this.selectedLines = []
    }

    async close() {
        ModalHelper.pop("download_excel");
    }

    async downloadSelected() {
        if (this.selectedLines && this.selectedLines.length > 0) {

            this.loading = true;

            let response = await metadataServices.getExcelLines({ elaborationDate : this.dataElaborazione, lines: this.selectedLines });
            if (response) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Lines.zip');
                document.body.appendChild(link);
                link.click();

                this.loading = false;
            }
        }


    }
}