import { CONFIGURATION } from "@/Configuration";
import { baseRestService, AuthToken } from "./_base/baseRestService";
import { MessageService } from "vue-mf-module";
import { store } from "@/store";
import moment from "moment";
import { Route } from "vue-router";
import { Model } from "vue-property-decorator";

class LoginServices extends baseRestService {

  constructor() {
    super();


    this.OnError = (e) => {
      const error = {
        title: e.data.name,
        details: e.data.message,
      };

      MessageService.Instance.send("ERROR", error);
      return null;
    };

    this.OnHeadersPreparing = (headers: any) => {
      headers['Content-Type'] = "application/x-www-form-urlencoded";
      return headers;
    };
  }


  /**
   *
   * Authentication method
   *
   * @param uri uri string for logging in
   * @param data object with email and password to authenticate user
   * @returns an AuthToken or null and set and authentication token to the local storage
   */
  public async Login(username: string, password: string, organizationId: string = null): Promise<AuthToken | null> {

    this.SetCurrentClientId(CONFIGURATION.auth.ClientId);

    const authData = new URLSearchParams();
    authData.append("username", username);
    authData.append("password", password);
    authData.append("client", "referer");
    authData.append("scope", CONFIGURATION.auth.Scope);
    authData.append("referer", window.location.origin);
    authData.append("f", "json");
    authData.append("client_id", loginServices.GetCurrentClientId());
    authData.append("grant_type", "password");

    const response = await this.Post<AuthToken>(CONFIGURATION.connectServiceUri, authData, { wid: organizationId });

    if (response) {
      this.setAuthenticationToken(response);
      //await usersService.whoAmI();

      MessageService.Instance.send('LOG_IN');
    }
    return response;

  }

  public async setToken(token: AuthToken) {
    this.setAuthenticationToken(token);
    //await usersService.whoAmI();
  }

  public async Logout() {
    try {
      MessageService.Instance.send("LOG_OUT");
      MessageService.Instance.send("PERMISSIONS_CHANGED", []);
      await this.Post(CONFIGURATION.connectServiceUri + "/logout", {}, {}, true);
    } catch { }

    this.deleteAuthenticationToken();
    // store.actions.login.setMyUserInfo(null);
    this.deleteClientId();

    var mydate = new Date();
    mydate.setTime(mydate.getTime() - 1);
    document.cookie = "username=; expires=" + (mydate as any).toGMTString();

    MessageService.Instance.send("ASK_LOGIN");
  }

  public async AutoRenewToken() {

    var token = await this.getAuthenticationToken();
    if (token) {
      await this.RefreshToken();
    }
    else {
      const route: Route = await MessageService.Instance.ask("ASK_ROUTE");
      // Avoid redirecting to login page from routes that does not need it...
      if (route.meta?.requireAuth === false) return;
      MessageService.Instance.send("ASK_LOGIN");
    }
  }


  /**
   * Refresh auth token in the local storage
   *
   * @returns an AuthToken or null if any error
   */
  public async RefreshToken(workspaceId: string | null = null): Promise<AuthToken> {
    const token = await this.getAuthenticationToken();

    const refreshData = new URLSearchParams();
    refreshData.append("scope", CONFIGURATION.auth.Scope);
    refreshData.append("client_id", loginServices.GetCurrentClientId());
    refreshData.append("grant_type", "refresh_token");
    refreshData.append("refresh_token", `${token.refresh_token}`);

    try {
      const response = await this.http.post<AuthToken>(CONFIGURATION.connectServiceUri, refreshData, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
      if (response.status == 200) {
        if (response.data.expires_in) {
          response.data.expiration_date = new Date(new Date().getTime() + response.data.expires_in * 1000).getTime();
        }

        if (!response.data.refresh_token)
          response.data.refresh_token = token.refresh_token;

        this.setAuthenticationToken(response.data);

        //await usersService.whoAmI();

        MessageService.Instance.send("TOKEN_CHANGED", response.data);
      }
      if (response.status >= 400) {
        this.deleteAuthenticationToken();
        MessageService.Instance.send("ASK_LOGIN");
      }
      return response.data;
    } catch (err) {
      MessageService.Instance.send("ASK_LOGIN");
      return null;
    }
  }

  public async GetCodeChallenge() {
    // got from : https://docs.cotter.app/sdk-reference/api-for-other-mobile-apps/api-for-mobile-apps

    // STEP 1: Create and store the code verifier
    function dec2hex(dec) {
      return ('0' + dec.toString(16)).slice(-2);
    }

    function generateRandomString() {
      var array = new Uint32Array(56 / 2);
      window.crypto.getRandomValues(array);
      return Array.from(array, dec2hex).join('');
    }

    const verifier = generateRandomString();
    localStorage.setItem("tmpVerifier", verifier);

    // STEP 2: Create and store the code verifier
    function sha256(plain) { // returns promise ArrayBuffer
      const encoder = new TextEncoder();
      const data = encoder.encode(plain);
      return window.crypto.subtle.digest('SHA-256', data);
    }

    function base64urlencode(a) {
      var str = "";
      var bytes = new Uint8Array(a);
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
        str += String.fromCharCode(bytes[i]);
      }
      return btoa(str)
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=+$/, "");
    }

    async function challenge_from_verifier(v) {
      const hashed = await sha256(v);
      const base64encoded = base64urlencode(hashed);
      return base64encoded;
    }

    var challenge = await challenge_from_verifier(verifier);

    return challenge;
  }

  public async GetAccessTokenFromCode(code: string) {
    const requestData = new URLSearchParams();
    requestData.append("grant_type", "authorization_code");
    requestData.append("scope", CONFIGURATION.auth.Scope);
    requestData.append("client_id", loginServices.GetCurrentClientId());
    requestData.append("code", code);
    requestData.append("redirect_uri", `${window.location.origin}/auth_callback`);

    var verifier = localStorage.getItem("tmpVerifier");
    if (verifier)
      requestData.append("code_verifier", verifier);

    try {
      const response = await this.http.post<AuthToken>(CONFIGURATION.connectServiceUri, requestData, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
      if (response.status == 200) {
        if (response.data.expires_in) {
          response.data.expiration_date = new Date(new Date().getTime() + response.data.expires_in * 1000).getTime();
        }

        this.setAuthenticationToken(response.data);

        //await usersService.whoAmI();

        MessageService.Instance.send("TOKEN_CHANGED", response.data);
      }

      if (response.status >= 400) {
        this.deleteAuthenticationToken();
        MessageService.Instance.send("ASK_LOGIN");
      }

      return response.data;
    } catch (err) {
      MessageService.Instance.send("ASK_LOGIN");
      return null;
    } finally {
      // Elimino il codice di verifica PKCE che non è più necessario.
      localStorage.removeItem("tmpVerifier");
    }

  }

  public async GetAccessToken() {
    var token = JSON.parse(localStorage.getItem("authorizationData"));
    if (!token || !token.expiration_date ||
      moment(Date.parse(token.expiration_date)).isBefore(Date.now())) {
      this.deleteAuthenticationToken();
      return null;
    }
    return token;
  }

  /// ----------------------------------------------------------------------------  ///
  ///                                 ATTENZIONE                                    ///
  ///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
  /// ----------------------------------------------------------------------------  ///
  protected setAuthenticationToken(data: AuthToken) {

    if (data.expires) {
      data.expiration_date = moment(data.expires).toDate().toISOString();
    }
    else
      data.expiration_date = moment().add(data.expires_in, "seconds").toDate().toISOString();
    localStorage.setItem("authorizationData", JSON.stringify(data));
    baseRestService._token = data;
  }

  /// ----------------------------------------------------------------------------  ///
  ///                                 ATTENZIONE                                    ///
  ///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
  /// ----------------------------------------------------------------------------  ///
  protected deleteAuthenticationToken() {
    localStorage.removeItem("authorizationData");
    baseRestService._token = null;
  }




  /// ----------------------------------------------------------------------------  ///
  ///                                 ATTENZIONE                                    ///
  ///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
  /// ----------------------------------------------------------------------------  ///
  protected deleteClientId() {
    localStorage.removeItem("clientId");
  }

  public SetCurrentClientId(id: string) {
    localStorage.setItem("clientId", id);
  }

  public GetCurrentClientId() {
    return localStorage.getItem("clientId") || null;
  }
}

export const loginServices = new LoginServices();
