import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class Radio extends Vue {
  @Prop({required: true})
  value: string;

  @Prop({required: true})
  select: string;

  @Prop()
  label: string;

  @Prop()
  id: string;
  
  public get radioValue() : string {
    return this.value;
  }
  public set radioValue(v : string) {
    this.$emit("input", v)
  }
}
