import Vue from "vue";
import { Component } from "vue-property-decorator";

import ModalOptions from "./@types/modal.options";
import { ModalHelper } from "./ModalHelper";

@Component({})
export default class ModalContainer extends Vue {
  public modalStack: ModalOptions[] = [];

  created() {
    ModalHelper.setContainer(this);
  }

  get current() {
    if (this.modalStack.length <= 0) {
      return null;
    }
    return this.modalStack[(this.modalStack.length - 1)];
  }

  public isComponent(item:ModalOptions) {
    return item && !(typeof (item.content) === "string");
  }

  public async close(id?: string) {
    const current = this.current
    if (id && current && current.callback) {
      const result = await current.callback(id, current.model);
      if (result) {
        this.popModal(current?.id);
      }
    } else {
      this.popModal(current?.id);
    }
  }

  public popModal(id?: string) {
    if (!id) {
      this.modalStack.pop();
    } else {
      const idx = this.modalStack.findIndex(m => m.id === id);
      if (idx !== -1) {
        this.modalStack.splice(idx, 1);
      }
    }
  }
}
