import { Vue, Component } from "vue-property-decorator";
import { Prop, Watch } from "vue-property-decorator";
import { store } from "@/store";

@Component({})
export default class DrawCanvas extends Vue {

    @Prop()
    imageWidth: number;

    @Prop()
    imageHeight: number;

    mouseDown = false;
    startPosition: any = { x: 0, y: 0 };
    endPosition: any = { x: 0, y: 0 };
    selectedCanvasArea: inspect.CanvasArea = null;

    get canvas() {
        return this.$refs["canvas"] as HTMLCanvasElement;
    }

    get canvasContext() {
        return this.canvas.getContext("2d");
    }

    get boundingClientRect() {
        return this.canvas.getBoundingClientRect();
    }

    get innerHeight() {
        return this.$el.getBoundingClientRect().height;
    }

    get innerWidth() {
        return this.$el.getBoundingClientRect().width;
    }

    async mounted() {
        this.canvas.height = this.innerHeight;
        this.canvas.width = this.innerWidth;
        await this.styleRectangle();
    }

    async styleRectangle() {
        this.canvasContext.setLineDash([6]);
        this.canvasContext.strokeStyle = "#f00";
        this.canvasContext.lineWidth = 5;
    }

    async handleMouseOut(event) {
        event.preventDefault();
        event.stopPropagation();
        this.mouseDown = false;
    }

    async handleMouseDown(event) {

        if (event) {
            this.canvasContext.clearRect(0, 0, this.innerWidth, this.innerHeight);
            this.mouseDown = true;
            this.startPosition.x = event.offsetX;
            this.startPosition.y = event.offsetY;
        }
    }

    async handleMouseUp(event) {

        if (event) {
            this.endPosition.x = event.offsetX;
            this.endPosition.y = event.offsetY;

            this.mouseDown = false;

            await this.onSelectedChange();

            this.startPosition.x = null;
            this.startPosition.y = null;
        }

    }

    async handleMouseMove(event) {

        if (event) {
            if (this.mouseDown) {
                await this.draw(event);
            }
        }
    }

    async draw(event) {
        this.canvasContext.beginPath();

        let diff = event.offsetX - this.startPosition.x;
        const _calculatedEndTop = ( diff* this.imageHeight)/this.imageWidth;

        this.selectedCanvasArea = {
            startLeft: this.startPosition.x,
            startTop: this.startPosition.y,
            endLeft: this.endPosition.x,
            endTop: this.endPosition.y,
            width: event.offsetX - this.startPosition.x,
            height: event.offsetY - this.startPosition.y
        } as inspect.CanvasArea

        this.canvasContext.rect(
            this.selectedCanvasArea.startLeft,
            this.selectedCanvasArea.startTop,
            this.selectedCanvasArea.width,
            _calculatedEndTop
            //this.selectedCanvasArea.height
        );
        
        this.canvasContext.closePath();
        this.canvasContext.fillRect(0, 0, this.innerWidth, this.innerHeight);
        this.canvasContext.clearRect(0, 0, this.innerWidth, this.innerHeight);

        this.canvasContext.stroke();
    }

    async clearSelection() {
        this.canvasContext.clearRect(0, 0, this.innerWidth, this.innerHeight);
    }

    async onSelectedChange() {
        if (this.selectedCanvasArea) {
            let area = {
                left: this.startPosition.x <= this.endPosition.x ? this.startPosition.x : this.endPosition.x,
                top: this.startPosition.y <= this.endPosition.y ? this.startPosition.y : this.endPosition.y,
                height: Math.abs(this.selectedCanvasArea.height),
                width: Math.abs(this.selectedCanvasArea.width),
            } as inspect.SelectedArea;

            this.$emit("onSelectedArea", area);

            setTimeout(async () => {
                await this.clearSelection();
            }, 300);


        }
    }
}