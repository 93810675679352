import Vue from 'vue'
import Component from 'vue-class-component';
import * as echarts from 'echarts';
import { Prop, Watch } from 'vue-property-decorator';
import { store } from '@/store';
import { ECharts } from 'echarts';
import { metadataServices } from '@/services/metadata.services';

@Component({
  components: {}
})
export default class ImageAnalytics extends Vue {

  @Prop({ default: '100%' }) private width!: string;

  @Prop({ default: '100%' }) private height!: string;

  @Prop()
  imageIds: string[]

  dataAvailabilityMessage: string = "Ricerca dati in corso"
  analyticsData: inspect.AnalyticsData = null
  deepEqual = require('deep-equal');
  issue_chart: ECharts | null;
  sost_chart: ECharts | null;
  camp_chart: ECharts | null;

  @Watch("analyticsData")
  async analyticsDataChanged(o, n) {
    if (!this.deepEqual(o, n)) {
      await this.initCharts()
      this.resizeCharts()
    }
  }

  get datasAvailable() {
    return this.issueData.length > 0 || this.sostData.length > 0 || this.campData.length > 0
  }

  get issueCategories() {
    return this.analyticsData?.issuesAnalytics.map(i => i.name)
  }

  get issueData() {
    return this.analyticsData?.issuesAnalytics ?? []
  }

  get sostData() {
    return this.analyticsData?.sostegniIssuesAnalytics ?? []
  }

  get campData() {
    return this.analyticsData?.campateIssuesAnalytics ?? []
  }

  get onTwoRows() {
    return this.campData.length == 0 || this.sostData.length == 0
  }

  get storedAnalyticsdata(){
    return store.getters.search.getAnalyticsData()
  }

  async mounted() {
    this.analyticsData = this.storedAnalyticsdata != null ? 
      this.storedAnalyticsdata : await metadataServices.getAnalytics( store.getters.search.getCurrentSearchData() )
    if (!this.datasAvailable) this.dataAvailabilityMessage = "Nessuna anomalia"
    this.initCharts()
  }

  initIssueChart() {
    this.issue_chart = echarts.init(document.getElementById("issue_chart") as HTMLDivElement, null, {
      renderer: 'svg',
      useDirtyRect: false,
    })
    this.issue_chart.setOption({
      title: {
        text: 'Incidenza anomalie'
      },
      tooltip: {
        position: [10, '80%'],
        trigger: 'item',
        formatter: '{b} : {c}',
        borderWidth: 2
      },
      legend: {
        orient: 'vertical',
        type: 'scroll',
        left: '58%',
        top: 30,
        textStyle: {
          width: 175,
          overflow: 'breakAll'
        }
      },
      series: [
        {
          type: 'pie',
          radius: '60%',
          center: ['30%', '50%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center'
          },
          labelLine: {
            show: false
          },
          data: this.issueData,
          itemStyle: {
            color: function (param) {
              return store.getters.domain.getIssueColor(param.data.name)
            }
          }
        }
      ]
    })
  }

  initSostChart() {
    let series: any[] = [];

    this.sostData.forEach(sd => {
      series.push({
        name: sd.name,
        type: 'bar',
        data: [sd.value],
        itemStyle: {
          color: store.getters.domain.getIssueColor(sd.name)
        }
      })
    })

    this.sost_chart = echarts.init(document.getElementById("sost_chart") as HTMLDivElement, null, {
      renderer: 'svg',
      useDirtyRect: false,
    })
    this.sost_chart.setOption({
      title: {
        text: 'Incidenza anomalie per Sostegni'
      },
      tooltip: {
        position: [10, '80%'],
        trigger: 'item',
        borderWidth: 2,
        formatter: '{a} : {c}'
      },
      legend: {
        data: this.issueCategories,
        orient: 'vertical',
        type: 'scroll',
        left: '63%',
        top: 30,
        textStyle: {
          width: 155,
          overflow: 'breakAll'
        }
      },
      xAxis: {
        type: 'category',
        show: false
      },
      yAxis: {
        type: 'value'
      },
      grid: {
        width: '50%'
      },
      series: series
    }
    )
  }

  initCampChart() {
    let series: any[] = [];

    this.campData.forEach(sd => {
      series.push({
        name: sd.name,
        type: 'bar',
        data: [sd.value],
        itemStyle: {
          color: store.getters.domain.getIssueColor(sd.name)
        }
      })
    })

    this.camp_chart = echarts.init(document.getElementById("camp_chart") as HTMLDivElement, null, {
      renderer: 'svg',
      useDirtyRect: false,
    })
    this.camp_chart.setOption({
      title: {
        text: 'Incidenza anomalie per Campate'
      },
      tooltip: {
        position: [10, '80%'],
        trigger: 'item',
        borderWidth: 2,
        formatter: '{a} : {c}'
      },
      legend: {
        data: this.issueCategories,
        orient: 'vertical',
        type: 'scroll',
        left: '63%',
        top: 30,
        textStyle: {
          width: 155,
          overflow: 'breakAll'
        }
      },
      xAxis: {
        type: 'category',
        show: false
      },
      yAxis: {
        type: 'value'
      },
      grid: {
        width: '50%'
      },
      series: series
    }
    )
  }

  async initCharts() {
    if (this.issueData.length > 0) {
      this.initIssueChart()
    }
    if (this.sostData.length > 0) {
      this.initSostChart()
    }
    if (this.campData.length > 0) {
      this.initCampChart()
    }
  }

  resizeCharts() {
    if (this.issueData.length > 0) {
      this.issue_chart.resize()
    }
    if (this.sostData.length > 0) {
      this.sost_chart.resize()
    }
    if (this.campData.length > 0) {
      this.camp_chart.resize()
    }
  }

}