export interface ImagesStoreModel {
    imageList: inspect.ImageModel[];
    missionList: inspect.Mission[];
    current: inspect.ImageModel;
    comparison: inspect.ImageModel[];
    imagesIssues: { [imageId: number]: inspect.Issue[] }
    imagesNote: { [imageId: number]: inspect.Note }
    imagesAssetsCount: { [imageId: number]: number }
}

export interface ImagesStoreActions {
    setList(model: inspect.ImageModel[]);
    resetList();
    setCurrent(model: inspect.ImageModel);
    setComparison(model: inspect.ImageModel[]);
    setImageIssue(model: { imageId: number, issues: inspect.Issue[] });
    setImageNote(model: { imageId: number, note: inspect.Note });
    setImagesAssetsCount(model: { imageId: number, assets: number });
}

export interface ImageStoreGetters {
    getImageList(): inspect.ImageModel[];
    getMissionList(): inspect.Mission[];
    getCurrent(): inspect.ImageModel;
    getComparison(): inspect.ImageModel[];
    getMission(mission: string): inspect.Mission;
    getImageIssue(imageId: number): inspect.Issue[];
    getImagesIssues(): { [imageId: number]: inspect.Issue[] };
    getImageNote(imageId: number): inspect.Note;
    getImagesNote(): { [imageId: number]: inspect.Note };
    getImageAssetsCount(imageId: number): number;
    getImagesAssetsCount(): { [imageId: number]: number };
}

export const ImagesStore = {
    PREFIX: "images",
    namespaced: true,
    state: {
        imageList: [],
        missionList: [],
        current: null,
        comparison: [],
        imagesIssues: {},
        imagesNote: {},
        imagesAssetsCount: {}
    },
    actions: {
        setList(context, model: inspect.ImageModel[]) {
            context.commit("setList", model)
        },
        resetList(context) {
            context.commit("resetList")
        },
        setCurrent(context, model: inspect.ImageModel) {
            context.commit("setCurrent", model)
        },
        setComparison(context, model: inspect.ImageModel[]) {
            context.commit("setComparison", model)
        },
        setImageIssue(context, model: { imageId: number, issues: inspect.Issue[] }) {
            context.commit("setImageIssue", model)
        },
        setImageNote(context, model: { imageId: number, note: inspect.Note }) {
            context.commit("setImageNote", model)
        },
        setImagesAssetsCount(context, model: { imageId: number, assets: number }) {
            context.commit("setImagesAssetsCount", model)
        },
    },
    mutations: {
        resetList(state: ImagesStoreModel) {
            state.imageList = []
            state.missionList = []
        },
        setList(state: ImagesStoreModel, model: inspect.ImageModel[]) {
            model.forEach(m => {
                state.imageList.push(m);
                let mission = state.missionList.find(ml => ml.missione == m.missione)
                if (mission) mission.images.push(m)
                else state.missionList.push({ missione: m.missione, images: [m] })
            })
        },
        setCurrent(state: ImagesStoreModel, model: inspect.ImageModel) {
            state.current = model;
        },
        setComparison(state: ImagesStoreModel, model: inspect.ImageModel[]) {
            state.comparison = model;
        },
        setImageIssue(state: ImagesStoreModel, model: { imageId: number, issues: inspect.Issue[] }) {
            var r = state.imagesIssues[model.imageId];
            if (r == null)
                state.imagesIssues[model.imageId] = model.issues;
            else
                r.splice(0, r.length, ...model.issues)
        },
        setImageNote(state: ImagesStoreModel, model: { imageId: number, note: inspect.Note }) {
            state.imagesNote[model.imageId] = model.note;
        },
        setImagesAssetsCount(state: ImagesStoreModel, model: { imageId: number, assets: number }) {
            state.imagesAssetsCount[model.imageId] = model.assets;
        },
    },
    getters: {
        getImageList: state => (): inspect.ImageModel[] => state.imageList,
        getMissionList: state => (): inspect.Mission[] => state.missionList,
        getCurrent: state => (): inspect.ImageModel => state.current,
        getComparison: state => (): inspect.ImageModel[] => state.comparison,
        getMission: state => (mission: string): inspect.Mission => state.missionList.find(m => m.missione == mission),
        getImageIssue: state => (imageId: number): inspect.Issue[] => state.imagesIssues[imageId],
        getImagesIssues: state => (): { [imageId: number]: inspect.Issue[] } => state.imagesIssues,
        getImageNote: state => (imageId: number): inspect.Note => state.imagesNote[imageId],
        getImagesNote: state => (): { [imageId: number]: inspect.Note } => state.imagesNote,
        getImageAssetsCount: state => (imageId: number): number => state.imagesAssetsCount[imageId],
        getImagesAssetsCount: state => (): { [imageId: number]: number } => state.imagesAssetsCount,
    }
}
