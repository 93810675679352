import { Vue, Component, Prop } from "vue-property-decorator";
import { store } from "@/store";
import ImageViewer from "@/components/imageViewer/imageViewer.vue";
import Tags from "@/components/tags/tags.vue";
import Slider from "@/components/slider/slider.vue";
import { List } from "echarts";

@Component({
  components: {
    ImageViewer,
    Tags,
    Slider,
  },
})
export default class ImageInspect extends Vue {

  @Prop()
  value: { images: inspect.ImageModel[], imageId?: number, note: inspect.Note };

  brightness = 100;
  contrast = 100;
  sharpness = 100;
  images: inspect.ImageModel[] = [];
  imageIndex: number = 0;
  highligthedIssue: inspect.Issue = null;

  get onConfront() {
    return this.value.imageId == null || this.value.imageId == undefined;
  }

  get modifyImageTitle() {
    return !this.onConfront ? "Modifica immagine" : "Modifica immagini";
  }

  get notesTitle() {
    return !this.onConfront ? "Note immagine" : `Note immagine ${this.value.images[0].id}`;
  }

  get imagesIssuesDict() {
    return store.getters.images.getImagesIssues();
  }

  get issuesList() {
    let imagesIssues: inspect.Issue[][] = [[], []];
    this.images.forEach((i, idx) => this.imagesIssuesDict[i.id].sort((a, b) => a.name.localeCompare(b.name))
      .forEach(d => imagesIssues[idx].push(d)));
    return imagesIssues;
  }

  imageDetail(n: number) {
    if (this.images && n > -1 && n < this.images.length) {
      return JSON.parse(this.images[n].json);
    }
    return {};
  }

  created() {
    if (!this.onConfront) {
      this.imageIndex = this.value.images.findIndex(img => img.id == this.value.imageId);
      this.images = [this.value.images[this.imageIndex]];
    } else {
      this.images = this.value.images;
    }
  }

  resetImagesChanges() {
    this.brightness = 100
    this.contrast = 100
    this.sharpness = 100
  }

  resetImageViewer() {
    if (this.$refs.imageviewer)
      this.$refs.imageviewer[0].zoomReset();
    let imageNote = store.getters.images.getImageNote(this.value.imageId)
    this.value.note = imageNote != undefined && imageNote != null ?
      JSON.parse(JSON.stringify(imageNote)) :
      {
        imageId: this.value.imageId,
        erroreAnomalia: false,
        nuovaAnomalia: false,
        note: ""
      }
  }

  forward() {
    this.images = [];
    this.value.imageId = this.value.images[++this.imageIndex].id;
    this.images.push(this.value.images.filter(img => img.id == this.value.imageId)[0]);
    this.resetImageViewer();
  }

  backward() {
    this.images = [];
    this.value.imageId = this.value.images[--this.imageIndex].id;
    this.images.push(this.value.images.filter(img => img.id == this.value.imageId)[0]);
    this.resetImageViewer();
  }

  async changeBrightness(arg) {
    if (arg) {
      this.brightness = arg;
    }
  }

  async changeContrast(arg) {
    if (arg) {
      this.contrast = arg;
    }
  }

  async changeSharpness(arg) {
    if (arg) {
      this.sharpness = arg;
    }
  }


  lastClickedIssueId = -1;
  async onClickIssueLabel(issue, key) {
    if (this.$refs.imageviewer &&
      (key === 0 || key === 1) &&
      (this.lastClickedIssueId !== issue.is || !this.$refs.imageviewer[key].disableLabelIssueClick)) {
      this.lastClickedIssueId = issue.id;
      this.$refs.imageviewer[key].zoomReset();
      this.$refs.imageviewer[key].scaleAndZommToIssue(issue);
    }
  }
}
