var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"imageviewer"},[_c('div',{key:_vm.imageKey,ref:"imgcontainerview",staticClass:"imageviewer-container"},[_c('img',{staticClass:"current-image",style:({
        filter:
          'brightness(' +
          _vm.brightness +
          '%) contrast(' +
          _vm.contrast +
          '%) saturate(' +
          _vm.sharpness +
          '%)',
      }),attrs:{"src":_vm.lastCroppedImage},on:{"load":_vm.loadedImage}}),(_vm.isImageLoaded && _vm.showIssues)?_c('display-label',{attrs:{"highligthedIssue":_vm.highligthedIssue,"labels":_vm.labels,"maxHeight":_vm.labelsBoxMaxHeight,"maxWidth":_vm.labelsBoxMaxWidth,"boxTop":_vm.labelsBoxTop,"boxLeft":_vm.labelsBoxLeft,"disableClick":_vm.disableLabelIssueClick},on:{"onClickedIssue":_vm.zoomToIssue}}):_vm._e(),(_vm.isZoomSelectionEnabled)?_c('draw-canvas',{ref:"draw-canvas",attrs:{"imageWidth":_vm.imageViewWidth,"imageHeight":_vm.imageViewHeight},on:{"onSelectedArea":_vm.onSelectedArea}}):_vm._e()],1),_c('div',{staticClass:"tools-container"},[_c('button',{staticClass:"second",on:{"click":_vm.zoomReset}},[_c('i',{staticClass:"ti ti-home"})]),_c('button',{staticClass:"second",on:{"click":_vm.zoomSelection}},[_c('i',{staticClass:"ti ti-marquee-2"})]),_c('button',{staticClass:"second",on:{"click":_vm.zoomOut}},[_c('i',{staticClass:"ti ti-minus"})]),_c('button',{staticClass:"second",on:{"click":_vm.zoomIn}},[_c('i',{staticClass:"ti ti-plus"})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }