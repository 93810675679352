import { render, staticRenderFns } from "./naming.vue?vue&type=template&id=637ee3f5&scoped=true&"
import script from "./naming.ts?vue&type=script&lang=ts&"
export * from "./naming.ts?vue&type=script&lang=ts&"
import style0 from "./naming.less?vue&type=style&index=0&id=637ee3f5&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "637ee3f5",
  null
  
)

export default component.exports