var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"dispalylabelcontainer",staticClass:"display-label-container"},_vm._l((_vm.labels),function(item,index){return _c('div',{key:index,staticClass:"label",class:{
      highligthed: _vm.highligthedIssue != null && _vm.highligthedIssue.id == item.id,
      enabledclick: !_vm.disableClick
    },style:({
      left: _vm.leftValue(item.left) + 'px',
      top: _vm.topValue(item.top)+ 'px',
      width: _vm.widthValue(item.width)+ 'px',
      height: _vm.heightValue(item.height)+ 'px',
    }),on:{"click":function($event){_vm.selectIssue({
      left: _vm.leftValue(item.left),
      top: _vm.topValue(item.top),
      width: _vm.widthValue(item.width),
      height: _vm.heightValue(item.height),
    })}}})}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }