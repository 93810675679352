import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';

@Component({})
export default class loadMoreItems extends Vue {

  observer = new IntersectionObserver(this.whenObservedChange, { threshold: 0.5 })

  @Prop({ default: true })
  loading!: boolean;

  visible: boolean = true;

  mounted() {
    this.observer.observe(this.$el);
  }

  beforeDestroy() {
    this.observer.disconnect();
  }

  whenObservedChange(entries: IntersectionObserverEntry[]) {
    for (const entry of entries) {

      if (entry.intersectionRatio > 0) {
        this.$emit("onview");
        this.visible = false;
      }
      else
        this.visible = true;
    }
  }
}