import { Vue, Component, Model, Prop } from "vue-property-decorator";
import { store } from "@/store";
import MapSearch from "@/components/mapSearch/mapSearch.vue";
import Map from "@/components/map/map.vue";
import MissionView from "@/components/card/missionView.vue";
import ImageView from "@/components/card/imageView.vue";
import { imageServices } from "@/services/image.services";
import Toggle from "@/components/toggle/toggle.vue";
import Radio from "@/components/radio/radio.vue";
import { ModalHelper } from "@/modals/ModalHelper";
import Naming from "@/components/naming/naming.vue";
import { metadataServices } from "@/services/metadata.services";
import DatePicker from 'vue2-datepicker';
import loadMore from "@/components/loadMore/loadMore.vue";
import DownloadExcel from "../downloadExcel/downloadExcel.vue";
@Component({
  components: {
    MapSearch,
    MissionView,
    ImageView,
    Map,
    Toggle,
    Radio,
    DatePicker,
    loadMore
  },
})
export default class Search extends Vue {

  @Prop({ default: "" })
  current_search: string

  deepEqual = require('deep-equal');
  isOpen: boolean;
  searchNotSaved = "";

  searching = false;
  research: inspect.SearchModel = {
    ids: [],
    asset: null,
    extent: null,
    issue: [],
    line: null,
    fromDate: null,
    toDate: null,
    elaborationFromDate: null,
    elaborationToDate: null,
    showProcessed: false,
    showNotProcessed: false,
    operator: []
  };

  get asset() {
    return this.research.asset
  }
  set asset(v:inspect.AssetType){
    this.research.asset = v
    if(v == null) this.research.line = null
  }

  get searchPage() {
    return store.getters.search.getSearchPage()
  }
  set searchPage(page: number) {
    store.actions.search.setSearchPage(page)
  }

  get showMission(): boolean {
    return store.getters.search.getShowMission()
  }
  set showMission(value: boolean) {
    store.actions.search.setShowMission(value)
    document.getElementById("cards").scroll({ top: 0 })
  }

  get imageList() {
    return store.getters.images.getImageList();
  }

  get missionList() {
    return store.getters.images.getMissionList();
  }

  get linesList() {
    return store.getters.domain.getLines();
  }

  get issueList() {
    return store.getters.domain.getIssues();
  }

  get assetsList() {
    return store.getters.domain.getAssets()
  }

  get operatorsList() {
    return ["Mario Rossi", "Paolo Bianchi", "Paolo Rossi", "Mario Bianchi", "Alessio Giombetti",
      "Alfeo Ragnacci", "Carlotta Rughi", "Lorenzo Rossi"];
  }

  get savedSearchList() {
    return store.getters.search.getSearchList();
  }

  get currentSearch() {
    return store.getters.search.getCurrentSearch()
  }

  get currentSearchData() {
    return store.getters.search.getCurrentSearchData()
  }

  //TOLTO IDS
  // get territorialDistricts() {
  //   let territorialDistrictList = store.getters.territorialDistrict.getTerritorialDistrictList()
  //   console.log(territorialDistrictList);
  //   return territorialDistrictList;
  // }

  get currentExtent() {
    return store.getters.territorialDistrict.getTerritorialDistrictExtent();
  }

  get dataElaborazione(): Date[] {
    return [
      this.research.elaborationFromDate != null ? new Date(this.research.elaborationFromDate) : null,
      this.research.elaborationToDate != null ? new Date(this.research.elaborationToDate) : null
    ]
  }
  set dataElaborazione(value: Date[]) {
    this.research.elaborationFromDate = value[0] == null ? null : new Date(value[0].getFullYear(), value[0].getMonth(), value[0].getDate(), 12, 0, 0)
    this.research.elaborationToDate = value[1] == null ? null : new Date(value[1].getFullYear(), value[1].getMonth(), value[1].getDate(), 12, 0, 0)
  }

  get dataScatto(): Date[] {
    return [
      this.research.fromDate != null ? new Date(this.research.fromDate) : null,
      this.research.toDate != null ? new Date(this.research.toDate) : null
    ]
  }
  set dataScatto(value: Date[]) {
    this.research.fromDate = value[0] == null ? null : new Date(value[0].getFullYear(), value[0].getMonth(), value[0].getDate(), 12, 0, 0)
    this.research.toDate = value[1] == null ? null : new Date(value[1].getFullYear(), value[1].getMonth(), value[1].getDate(), 12, 0, 0)
  }

  async mounted() {
    this.setCurrentSearch(this.current_search == "" ? this.currentSearch : JSON.parse(this.current_search))
  }

  openExcelDownloadModal() {
    ModalHelper.push({
      title: `Scarica Excel per Linea`,
      content: DownloadExcel,
      id: "download_excel",
      closeButton: false,
      hideFooter: true,
      callback: async (id, model) => {
        if (id === "confirm") { }
        return true;
      }
    })
  }

  loadMore() {
    if (this.searchPage >= 0)
      this.search(true)
  }

  setCurrentSearch(search: inspect.DeserializedStoredSearch) {
    if (search == null) this.resetSearch()
    else {
      this.resetListOnCurrentSearchChange(search.jsonData)
      store.actions.search.setCurrentSearch(search)
      Object.assign(this.research, search.jsonData)
      //TOLTO IDS
      //store.actions.territorialDistrict.setTerritorialDistrictList(this.research.ids.map(dt => dt.toString()))
      store.actions.territorialDistrict.setTerritorialDistrictExtent(this.research.extent);
    }
  }

  openTerritorialDistrict() {
    //TOLTO IDS
    //store.actions.territorialDistrict.setTerritorialDistrictList([])
    store.actions.territorialDistrict.setTerritorialDistrictOpen(true)
    //store.actions.territorialDistrict.setTerritorialDistrictExtent(null);
  }

  async resetSearch() {
    this.research = {
      ids: [],
      extent: null,
      asset: null,
      issue: [],
      line: null,
      fromDate: null,
      toDate: null,
      elaborationFromDate: null,
      elaborationToDate: null,
      showProcessed: false,
      showNotProcessed: false,
      operator: []
    }
    this.searchPage = -1
    store.actions.images.resetList()
    //TOLTO IDS
    //store.actions.territorialDistrict.setTerritorialDistrictList([])
    store.actions.territorialDistrict.setTerritorialDistrictExtent(null)
    store.actions.search.setCurrentSearch(null)
  }

  async search(fromLoadMore: boolean = false) {
    this.searching = true;

    store.actions.search.setAnalyticsData(null)
    store.actions.search.setMapHomeSearch(null)

    const _currentExtent = store.getters.territorialDistrict.getTerritorialDistrictExtent();
    if (_currentExtent)
      this.research.extent = _currentExtent;

    if (this.currentSearch == null || (!fromLoadMore && !this.deepEqual(this.currentSearchData, this.research))) {
      store.actions.search.setCurrentSearch({
        jsonData: Object.assign({}, this.research),
        nome: this.searchNotSaved,
        operatore: "utente.corrente@info.it"
      })
    }

    //VECCHIA RICERCA PER ID
    //this.research.ids = store.getters.territorialDistrict.getTerritorialDistrictList();

    if (fromLoadMore) {
      let obj = Object.assign({}, this.currentSearchData)
      this.searchPage = this.searchPage + 1
      await imageServices.search(obj, this.searchPage)
    }
    else {
      store.actions.images.resetList()
      this.searchPage = 0
      await imageServices.search(this.research, this.searchPage);
    }
    this.searching = false;
  }

  resetListOnCurrentSearchChange(research: inspect.SearchModel) {
    if (!this.deepEqual(this.currentSearchData, research)) {
      store.actions.images.resetList()
    }
  }

  namingSearch() {
    var newSearch = this.currentSearch == null || this.currentSearch.nome == this.searchNotSaved;
    ModalHelper.push({
      title: newSearch ? `Salva Ricerca` : `Cancella/Modifica Ricerca`,
      content: Naming,
      id: "naming_search",
      closeButton: true,
      hideFooter: false,
      model: {
        name: newSearch ? "" : this.currentSearch.nome
      },
      buttons: [
        { class: "second", id: "undo", title: "Annulla" },
        { class: `third ${newSearch ? " not-visible" : ""}`, id: "delete", title: "Cancella" },
        { id: "confirm", title: `${newSearch ? "Salva" : "Aggiorna"}` }
      ],
      callback: async (id, model) => {
        if (id === "confirm") {
          if (model.name == "") this.namingSearch()
          else {
            let storedSearchToSave: inspect.StoredSearch = null
            //TOLTO IDS
            //this.research.ids = store.getters.territorialDistrict.getTerritorialDistrictList();
            this.research.extent = store.getters.territorialDistrict.getTerritorialDistrictExtent();

            if (newSearch) {
              storedSearchToSave = {
                jsonData: JSON.stringify(this.research),
                nome: model.name,
                operatore: ""
              }
            } else {
              storedSearchToSave = {
                operatore: this.currentSearch.operatore,
                id: this.currentSearch.id,
                dataInserimento: this.currentSearch.dataInserimento,
                nome: model.name,
                jsonData: JSON.stringify(this.research)
              } as inspect.StoredSearch
            }

            this.resetListOnCurrentSearchChange(this.research)
            //console.log("storedSearchToSave:", storedSearchToSave);
            await metadataServices.saveSearch(storedSearchToSave)
          }
        }
        if (id == "delete") {
          await metadataServices.deleteSearch(this.currentSearch)
          this.resetSearch()
        }
        return true;
      }
    })
  }
}
