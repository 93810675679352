import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import EsriMap from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import SceneView from "@arcgis/core/views/SceneView";
import Measurement from "@arcgis/core/widgets/Measurement";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";
import FeatureEffect from "@arcgis/core/layers/support/FeatureEffect.js";
import FeatureFilter from "@arcgis/core/layers/support/FeatureFilter.js";
import { UniqueValueRenderer } from "@arcgis/core/renderers";
import * as geometryEngineAsync from "@arcgis/core/geometry/geometryEngineAsync.js";
import esriConfig from "@arcgis/core/config";
import settings from "@/settings";
import Search from "@arcgis/core/widgets/Search";
import SketchViewModel from "@arcgis/core/widgets/Sketch/SketchViewModel";
import LayerList from "@arcgis/core/widgets/LayerList";
import BasemapGallery from "@arcgis/core/widgets/BasemapGallery";
import Expand from "@arcgis/core/widgets/Expand";
import { metadataServices } from "@/services/metadata.services";
import Popup from "@arcgis/core/widgets/Popup.js";
import * as popupUtils from "@arcgis/core/support/popupUtils.js";
import { GetExposurePointPopup, GetCampatePopup, GetSostegniPopup, GetLineePopup } from "@/utils/layerPopupTemplate";


@Component({})
export default class Map extends Vue {

    @Prop({ default: 5 })
    zoom: number;

    @Prop({ default: () => [12, 41] })
    center: number[];

    @Prop({ default: "View" })
    mode: "View" | "Explore" | "Situation";

    @Prop({ default: null })
    value!: Array<locations.MapLayer>;

    @Prop({ default: false })
    showLayerList: boolean;

    @Prop({ default: true })
    showSelectionTool: boolean;

    @Prop({ default: true })
    showMeasurement: boolean;

    @Prop({ default: true })
    showIOCTool: boolean;

    @Prop({ default: true })
    showSearchWidget: boolean;

    @Prop({ default: [] })
    imageIds: string[]

    @Prop({ default: "" })
    highlightedImage: string

    appConfig = null;
    initialViewParams = null;
    mapConfig = null;
    webMap = null;
    scene = null;
    activeView: __esri.MapView | __esri.SceneView = null;
    switchButton = null;
    map: __esri.Map = null;
    sceneView: __esri.SceneView = null;
    mapView: __esri.MapView = null;
    is3d: boolean = false;
    measurement: Measurement = null;

    exposurePointLayer: __esri.FeatureLayer = null;
    exposurePointLayerId: string = "exposure-points-layer-id";
    exposurePointLayerImageNameField = "name";
    exposurePointLayerIdField = "objectid";

    sostegniLayerId: string = "sostegni-layer-id";
    campateLayerId: string = "campate-layer-id";
    linesLayerId: string = "lines-layer-id";
    imageProjectionLayerId: string = "projection-layer-id";
    symbolGraphicLayerId: string = "symbol-graphic-layer-id";

    imageProjectionLayer: __esri.FeatureLayer = null;
    sostegniLayer: __esri.FeatureLayer = null;
    campateLayer: __esri.FeatureLayer = null;
    linesLayer: __esri.FeatureLayer = null;
    symbolGraphicLayer: __esri.GraphicsLayer = null;

    polygonGraphicsLayer: __esri.GraphicsLayer = null;
    sketchViewModel: __esri.SketchViewModel = null;
    geometryEngineAsync: __esri.geometryEngineAsync = null;
    uniqueValueInfos: any[] = []

    exposurePointPopupTemplate = null;
    campatePointPopupTemplate = null;
    sostegniPointPopupTemplate = null;
    lineePointPopupTemplate = null;

    allPopupEnabled = false;

    @Watch("imageIds")
    imageIdsChanged() {
        if (this.exposurePointLayer) {
            if (this.imageIdsQuery) {
                // (this.activeView.allLayerViews.find(lv => lv.layer.id == this.exposurePointLayer.id) as any)
                // .filter = new FeatureFilter({
                //     where: this.imageIdsQuery,
                // })
                this.exposurePointLayer.visible = true
                this.exposurePointLayer.definitionExpression = this.imageIdsQuery
                this.exposurePointLayer.renderer = this.renderer
            }
            else {
                this.exposurePointLayer.visible = false
            }
        }
        if (this.imageProjectionLayer)
            this.imageProjectionLayer.visible = false
    }

    @Watch("highlightedImage")
    highlightedImageChanged(n, o) {
        this.uniqueValueInfos = []
        if (this.highlightedImage != "") {
            this.uniqueValueInfos.push({
                value: this.highlightedImage,
                symbol: this.highlightedSymbol
            });
        }

        if (this.exposurePointLayer) {
            this.exposurePointLayer.renderer = this.renderer;
        }
        if (this.imageProjectionLayer) {
            if (this.highlightedImage != "")
                this.imageProjectionLayer.featureEffect = new FeatureEffect({
                    filter: new FeatureFilter({
                        where: `Id IN (${this.highlightedImage})`
                    }),
                    includedEffect: "drop-shadow(0px 0px 15px yellow)"
                });
            else
                this.imageProjectionLayer.featureEffect = null
        }
    }

    async mounted() {
        var token = (await metadataServices.getArcgisToken()).token;
        esriConfig.request.interceptors.push({
            // set the `urls` property to the URL of the FeatureLayer so that this
            // interceptor only applies to requests made to the FeatureLayer URL
            urls: [
                settings.map.exposurePoint,
                settings.map.sostegniLayer,
                settings.map.linesLayer,
                settings.map.imageProjection,
                settings.map.campateLayer
            ],
            // use the BeforeInterceptorCallback to add token to query
            before: function (params) {
                params.requestOptions.query.token = token;
            },
        });

        esriConfig.apiKey = settings.map.apiKey;
        await this.createMap();
    }

    get imageIdsQuery(): string {
        if (this.imageIds.length == 0) return null
        let ids = ''
        this.imageIds.forEach(id => {
            ids += `'${id}',`
        })
        ids = ids.slice(0, ids.length - 1)
        return `name in (${ids})`
    }

    get defaultSymbol(): SimpleMarkerSymbol {
        return new SimpleMarkerSymbol({
            style: "circle",
            color: [0, 154, 242, 255],
            size: "20px",
            outline: null
        });
    }

    get highlightedSymbol(): SimpleMarkerSymbol {
        return new SimpleMarkerSymbol({
            style: "circle",
            color: [0, 154, 242, 255],
            size: "27px",
            outline: {
                width: 2,
                color: "yellow"
            }
        });
    }

    get renderer(): UniqueValueRenderer {
        return new UniqueValueRenderer({
            defaultSymbol: this.defaultSymbol,
            field: "name",
            uniqueValueInfos: this.uniqueValueInfos
        });
    }

    async createMap() {
        this.map = new EsriMap({
            basemap: settings.map.basemap
        });

        this.activeView = this.mapView = new MapView({
            container: this.$refs.mapDiv as HTMLDivElement,
            map: this.map,
            zoom: this.zoom,
            center: this.center,
            popupEnabled: true
        });


        // this.appConfig.sceneView.on("click", e => {
        //     this.onMapClick(this.sceneView, e);
        // })

        this.activeView.on("click", e => {

            // this.activeView.openPopup({

            // });
            this.onMapClick(this.activeView, e);
        })

        //CONTROLLI MAPPA
        if (this.showSearchWidget) {
            await this.initializeSearchWidget();
        }

        this.initializeBaseMapGallery();

        if (this.showLayerList) {
            await this.initializeLayerList();
        }

        await this.initializeToolbarButton();
        await this.initiliazePolygonSelectGraphicsLayer();
        if (this.showMeasurement) {
            await this.inizializeMeasurementTool(this.activeView);
        }

        if (this.showSelectionTool) {
            await this.initializeSelectByRectangle();
        }
        // FINE CONTROLLI MAPPA

        //LAYER
        
        await this.initializeProjectionLayer();
        await this.initializeSostegniLayer();
        await this.initializeLinesLayer();
        await this.initializeCampateLayer();
        await this.initializeSymbolGraphicLayer();
        await this.initializeExposurePointLayer();
    }

    async createView(params, type) {
        let view;
        if (type === "2d") {
            view = new MapView(params);
            return view;
        } else {
            view = new SceneView(params);
        }
        return view;
    }

    async enableAllPopup() {
        this.allPopupEnabled = !this.allPopupEnabled;

        if (this.campateLayer)
            this.campateLayer.popupEnabled = this.allPopupEnabled;

        if (this.sostegniLayer)
            this.sostegniLayer.popupEnabled = this.allPopupEnabled;

        if (this.linesLayer)
            this.linesLayer.popupEnabled = this.allPopupEnabled;
    }

    async switchView() {
        const is3D = this.appConfig.activeView.type === "3d";
        const activeViewpoint = this.appConfig.activeView.viewpoint.clone();

        // remove the reference to the container for the previous view
        this.appConfig.activeView.container = null;

        if (is3D) {
            // if the input view is a SceneView, set the viewpoint on the
            // mapView instance. Set the container on the mapView and flag
            // it as the active view
            this.appConfig.mapView.viewpoint = activeViewpoint;
            this.appConfig.mapView.container = this.appConfig.container;
            this.appConfig.activeView = this.appConfig.mapView;

            this.appConfig.mapView.on("click", e => {
                this.onMapClick(this.sceneView, e);
            })
            this.switchButton.value = "3D";
        } else {
            this.appConfig.sceneView.viewpoint = activeViewpoint;
            this.appConfig.sceneView.container = this.appConfig.container;
            this.appConfig.activeView = this.appConfig.sceneView;

            this.appConfig.sceneView.on("click", e => {
                this.onMapClick(this.sceneView, e);
            })
            this.switchButton.value = "2D";
        }
    }

    async initializeToolbarButton() {
        this.activeView.ui.add("toolbarDiv", "top-right")
        this.activeView.ui.move("zoom", "bottom-right");
    }

    async initializeBaseMapGallery() {
        const basemapGallery = new BasemapGallery({
            view: this.activeView
        });
        new Expand({
            view: this.activeView,
            content: basemapGallery,
            expanded: false,
            container: "tools"
        });
    }

    async initializeLayerList() {
        const layerList = new LayerList({ view: this.activeView });

        new Expand({
            view: this.activeView,
            content: layerList,
            expanded: false,
            container: "tools"
        });
    }

    async initializeSearchWidget() {
        new Search({
            container: "search",
            view: this.activeView
        });
    }

    async initiliazePolygonSelectGraphicsLayer() {
        this.polygonGraphicsLayer = new GraphicsLayer();
        this.polygonGraphicsLayer.listMode = "hide";
        this.activeView.map.add(this.polygonGraphicsLayer);

        this.sketchViewModel = new SketchViewModel({
            view: this.activeView,
            layer: this.polygonGraphicsLayer,
        });

        this.sketchViewModel.on("create", async (event) => {
            if (event.state === "complete") {
                // this polygon will be used to query features that intersect it
                const geometries = this.polygonGraphicsLayer.graphics.map(function (
                    graphic
                ) {
                    return graphic.geometry;
                });
                const queryGeometry = await geometryEngineAsync.union(
                    geometries.toArray()
                );

                this.selectFeatures(queryGeometry);
            }
        });
    }


    async selectFeatures(geometry) {

        if (this.exposurePointLayer) {
            const query = {
                geometry: geometry,
                outFields: ["*"]
            };

            this.exposurePointLayer.queryFeatures(query)
                .then(async (results) => {
                    if (results.features.length === 0) {
                        await this.clearSelection();
                    } else {
                        this.$emit("onSelectedFeatureChanged", results.features);
                    }
                })
                .catch((error) => {
                    console.log("error happened:", error.message);
                });

        }
    }

    async clearSelection() {
        this.polygonGraphicsLayer.removeAll();
    }

    async inizializeMeasurementTool(view) {
        this.measurement = new Measurement();
        view.ui.add(this.measurement, "bottom-left");
        this.measurement.view = view;
    }

    async doMeasure() {
        const type = this.activeView.type;
        this.measurement.activeTool = type.toUpperCase() === "2D" ? "distance" : "direct-line";
    }

    async clearMeasure() {
        this.measurement.clear();
    }

    async initializeSelectByRectangle() {

        const selectPointButton = this.$refs["select-by-point"] as HTMLDivElement;
        selectPointButton.addEventListener("click", () => {
            this.activeView.closePopup();
            this.sketchViewModel.create("point");
        });


        const selectRectangleButton = this.$refs["select-by-rectangle"] as HTMLDivElement;
        selectRectangleButton.addEventListener("click", () => {
            this.activeView.closePopup();
            this.sketchViewModel.create("rectangle");
        });


        const clearSelectButton = this.$refs["clear-selection"] as HTMLDivElement;
        clearSelectButton.addEventListener("click", () => {
            this.polygonGraphicsLayer.removeAll();
        });
    }

    async queryImageExposurePoint() {

    }

    async initializeSymbolGraphicLayer() {
        if (!this.activeView.map.findLayerById(this.symbolGraphicLayerId)) {
            this.symbolGraphicLayer = new GraphicsLayer({
                id: this.symbolGraphicLayerId,
                elevationInfo: { mode: "absolute-height", offset: 0 },
                visible: true,
                listMode: "hide"
            });
            this.activeView.map.add(this.symbolGraphicLayer);
        }
    }

    async initializeCampateLayer() {
        const template = GetCampatePopup();
        if (!this.activeView.map.findLayerById(this.campateLayerId)) {
            this.campateLayer = new FeatureLayer({
                id: this.campateLayerId,
                url: settings.map.campateLayer,
                minScale: 0,
                maxScale: 0,
                outFields: ["*"],
                popupEnabled:false,
                popupTemplate: template
            });
            this.activeView.map.add(this.campateLayer);
        }
    }

    async initializeLinesLayer() {
        const template = GetLineePopup();
        if (!this.activeView.map.findLayerById(this.linesLayerId)) {
            this.linesLayer = new FeatureLayer({
                id: this.linesLayerId,
                url: settings.map.linesLayer,
                minScale: 0,
                maxScale: 0,
                outFields: ["*"],
                popupEnabled:false,
                popupTemplate: template
            });
            this.activeView.map.add(this.linesLayer);
        }
    }

    async initializeSostegniLayer() {
        const template = GetSostegniPopup();
        if (!this.activeView.map.findLayerById(this.sostegniLayerId)) {
            this.sostegniLayer = new FeatureLayer({
                id: this.sostegniLayerId,
                url: settings.map.sostegniLayer,
                minScale: 0,
                maxScale: 0,
                outFields: ["*"],
                popupEnabled:false,
                popupTemplate: template
            });
            this.activeView.map.add(this.sostegniLayer);
        }
    }

    async initializeExposurePointLayer() {
        //const template = GetExposurePointPopup();
        if (!this.activeView.map.findLayerById(this.exposurePointLayerId)) {
            this.exposurePointLayer = new FeatureLayer({
                id: this.exposurePointLayerId,
                url: settings.map.exposurePoint,
                minScale: 0,
                maxScale: 0,
                outFields: ["*"],
                //popupTemplate:template
            });

            this.exposurePointLayer.when(() => {
                return this.exposurePointLayer.queryExtent();
            }).then((response) => {
                if (response && response.extent) {
                    this.activeView.goTo(response.extent);
                    this.activeView.zoom = 12;
                }
            });

            this.activeView.map.add(this.exposurePointLayer);

            if (this.imageIdsQuery) {
                this.exposurePointLayer.definitionExpression = this.imageIdsQuery
                this.exposurePointLayer.renderer = this.renderer;
            }
        }
    }

    async initializeProjectionLayer() {
        if (!this.activeView.map.findLayerById(this.imageProjectionLayerId)) {
            this.imageProjectionLayer = new FeatureLayer({
                id: this.imageProjectionLayerId,
                url: settings.map.imageProjection,
                listMode: "hide",
                minScale: 0,
                maxScale: 0,
                outFields: ["*"],
                visible: false
            });
            this.activeView.map.add(this.imageProjectionLayer);
        }
    }

    async showHideSostegniLayer() {
        if (this.sostegniLayer) {
            this.sostegniLayer.visible = !this.sostegniLayer.visible;
        }
    }

    async showHideCampateLayer() {
        if (this.campateLayer) {
            this.campateLayer.visible = !this.campateLayer.visible;
        }
    }

    async showHideLinesLayer() {
        if (this.linesLayer) {
            this.linesLayer.visible = !this.linesLayer.visible;
        }
    }

    async showHideExposurePointLayer() {
        if (this.exposurePointLayer) {
            this.exposurePointLayer.visible = !this.exposurePointLayer.visible;
        }
    }

    async removeLayer(layerId: string) {
        console.log("removeLayer layerId:",layerId);
        if (this.activeView.map.findLayerById(layerId)) {
            this.activeView.map.remove(this.activeView.map.findLayerById(layerId));
        }
    }

    async onMapClick(view: __esri.MapView | __esri.SceneView, e: __esri.ViewClickEvent) {

        await this.onExposurePointClick([]);

        view.hitTest(e).then(async (response: __esri.HitTestResult) => {

            console.log(response);

            if (response && response.results && response.results.length <= 0) {
                await this.resetImageProjectionVisibility();
            }else if(response && response.results && response.results.length > 0 && response.results[0] && response.results[0].layer && response.results[0].layer.id){
                if(response.results[0].layer.id === this.imageProjectionLayerId){
                    await this.resetImageProjectionVisibility();
                }
            }

            if (response && response.results && response.results.length > 0 && response.results[0].type === "graphic" && response.results[0].layer.id === this.exposurePointLayerId) {
                const exposurePointResult = [];//as inspect.ImageModel[];
                if (response.results && response.results.length > 0) {
                    for (let index = 0; index < response.results.length; index++) {
                        const graphic = response.results[index] as __esri.GraphicHit;
                        if (graphic && graphic?.graphic && graphic.graphic.attributes) {
                            //console.log(graphic.graphic.attributes);

                            let _imageName = graphic.graphic.attributes[this.exposurePointLayerImageNameField];

                            if (_imageName) {
                                exposurePointResult.push(
                                    {
                                        altitude: 0, fileName: `${graphic.graphic.attributes["image"]}.json`, id: 0, latitude: 0, longitude: 0, missione: "", pitch: 0, roll: 0, yaw: 0, dataAcquisizione: null, dataScatto: null, json: null,
                                        mapPoint: graphic.mapPoint,
                                        distance: graphic["distance"],
                                        name: graphic.graphic.attributes[this.exposurePointLayerImageNameField],
                                        source: `${settings.map.imageProjection}${graphic.graphic.attributes[this.exposurePointLayerImageNameField]}${".JPG"}`,
                                        objectId: graphic.graphic.attributes[this.exposurePointLayerIdField],
                                    }
                                );
                            }

                        }
                    }
                }
                await this.queryImageProjection(exposurePointResult.map(a => a.fileName));
                await this.onExposurePointClick(exposurePointResult);
            }
        });
    }

    getImageIdsQuery(fileNames: string[]): string {
        let ids = ""
        fileNames.forEach(id => { ids += `'${id}',` })
        ids = ids.slice(0, ids.length - 1)
        let res = `FileName IN (${ids})`;
        return res;
    }

    async resetImageProjectionVisibility() {
        this.imageProjectionLayer.visible = false;
    }

    async queryImageProjection(fileNames: string[]) {
        let clause = this.getImageIdsQuery(fileNames)
        console.log("clause: ",clause);
        let l = (this.activeView.allLayerViews.find(lv => lv.layer.id == this.imageProjectionLayer.id) as any);
        console.log("l",l)
        if(l){
            l.filter = new FeatureFilter({
                where: clause,
            });

            this.imageProjectionLayer.visible = true;
        }
        // (this.activeView.allLayerViews.find(lv => lv.layer.id == this.imageProjectionLayer.id) as any)
        //     .filter = new FeatureFilter({
        //         where: clause,
        //     })
    }

    async onExposurePointClick(results: inspect.ImageModel[]) {
        this.$emit('onExposurePointClick', results);
    }

}