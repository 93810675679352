import { baseRestService } from "@/services/_base/baseRestService";
import settings from "@/settings";
import { store } from "@/store";

class MetadataServices extends baseRestService {

    constructor() {
        super();
        this.baseUrl = () => `${settings.inspectAnalyzerServiceUrl}/api/`;
    }

    async getArcgisToken() {
        let res = await this.Get<arcgis.AuthToken>("arcgis/token");
        return res;
    }

    async getMissions(missione?: string, page: number = 0, limit: number = 50): Promise<inspect.Mission[]> {
        let res = await this.Get<inspect.Mission[]>("metadata/missions", { missione: missione, page: page, limit: limit });
        return res;
    }

    async getLines(strno?: string, pltxt?: string, page: number = 0, limit: number = 50): Promise<inspect.Line[]> {
        let res = await this.Get<inspect.Line[]>("metadata/lines", { strno: strno, pltxt: pltxt, page: page, limit: limit });
        store.actions.domain.setLines(res);
        return res;
    }

    async getAnalytics(search: inspect.SearchModel): Promise<inspect.AnalyticsData> {
        let res = await this.Post<inspect.AnalyticsData>(`metadata/analytics`,search);
        store.actions.search.setAnalyticsData(res)
        return res;
    }

    // async getAnalytics(imageIds: string[]): Promise<inspect.AnalyticsData> {
    //     let res = await this.Get<inspect.AnalyticsData>(`metadata/analytics?ImageIds=${JSON.stringify(imageIds)}`);
    //     store.actions.search.setAnalyticsData(res)
    //     return res;
    // }

    async getComponents(): Promise<inspect.Component[]> {
        let res = await this.Get<inspect.Component[]>("metadata/components");
        store.actions.domain.setComponents(res);
        return res;
    }

    async getIssueTypes(): Promise<inspect.IssueType[]> {
        let res = await this.Get<inspect.IssueType[]>("metadata/issuetype");
        store.actions.domain.setIssues(res)
        store.actions.domain.setIssueRandomColors(res.map(r => r.anomalia))
        return res;
    }

    async getSearchs(): Promise<inspect.DeserializedStoredSearch[]> {
        let deserializedStoredSearchs = await this.Get<inspect.DeserializedStoredSearch[]>("metadata/storedsearch/list");
        store.actions.search.setSearchList(deserializedStoredSearchs);
        return deserializedStoredSearchs;
    }

    async saveSearch(storedSearch: inspect.StoredSearch) {
        let deserializedStoredSearch = await this.Post<inspect.DeserializedStoredSearch>("metadata/storedsearch/save", storedSearch);
        store.actions.search.addToSearchList(deserializedStoredSearch)
        store.actions.search.setCurrentSearch(deserializedStoredSearch)
        return deserializedStoredSearch;
    }

    async deleteSearch(storedSearch: inspect.DeserializedStoredSearch): Promise<boolean> {
        let res = await this.Delete<boolean>("metadata/storedsearch/delete", { id: storedSearch.id });
        store.actions.search.deleteFromSearchList(storedSearch)
        return res;
    }

    async getNotes(imageIds: number[]): Promise<inspect.Note[]> {
        let notes = await this.Get<inspect.Note[]>(`metadata/notes/list?ImageIds=${JSON.stringify(imageIds)}`);
        notes.forEach(n => store.actions.images.setImageNote({ imageId: n.imageId, note: n }));
        return notes;
    }

    async saveNote(note: inspect.Note) {
        let result = await this.Post<inspect.Note>("metadata/notes/save", note);
        store.actions.images.setImageNote({ imageId: result.imageId, note: result })
        return result;
    }

    async deleteNote(note: inspect.Note): Promise<boolean> {
        let res = await this.Delete<boolean>("metadata/notes/delete", { ImageId: note.imageId });
        store.actions.images.setImageNote({ imageId: note.imageId, note: null })
        return res;
    }

    async getExcelLines(lines:inspect.ExportLines) : Promise<any> {
        return await this.postRaw(`metadata/excel/lines`,lines,'blob');
    }
}

export const metadataServices = new MetadataServices();