import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Search from '@/views/search/search.vue';
import UserSearch from '@/views/usersearch/usersearch.vue';
import MapHome from '@/views/mapHome/mapHome.vue';
import SavedSearch from '@/views/savedSearch/savedSearch.vue';
import Login from '@/views/login/Login.vue';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: Search,
    props: true
  },
  {
    path: "/map_home",
    name: "map_home",
    component: MapHome,
    props: true
  },
  {
    path: "/saved_search",
    name: "saved_search",
    component: SavedSearch
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    children: [
      {
        path: "",
        name: "mailLogin",
        alias: "/",
        meta: {
          requireAuth: false
        },
        component: () => import('@/components/login/Login.vue'),
      }]
  }

]

const router = new VueRouter({
  routes
})

export default router
