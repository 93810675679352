import axios from "axios";
import { metadataServices } from "./services/metadata.services";
import { CONFIGURATION } from "./Configuration";

declare const PRODUCTION;

interface IMapSettings {
  apiKey: string;
  basemap: string;
  exposurePoint: string,
  sostegniLayer: string,
  linesLayer: string,
  campateLayer:string,
  imageProjection: string,
}

interface ISettings {
  inspectAnalyzerServiceUrl: string,
  map: IMapSettings,
  serverImageWidth: number,
  serverImageHeight: number
}

const settings: ISettings = {
  inspectAnalyzerServiceUrl: 'http://localhost:5000',
  map: {
    apiKey: null,
    basemap: null,
    exposurePoint: null,
    sostegniLayer: null,
    linesLayer: null,
    campateLayer:null,
    imageProjection: null,
  },
  serverImageWidth: 14204,
  serverImageHeight: 10652
}

export async function loadSettings() {

  let response = null;
  try {
    response = await axios.get("/settings.json");
    Object.assign(settings, response.data);
    Object.assign(CONFIGURATION, response.data.configuration)
  } catch { }

  try {
    if (PRODUCTION !== undefined && PRODUCTION) {
      console.error("NO CONFIGURATION FOUND !!", "please provide settings.json or settings.custom.json file !")
    }
  } catch { }
}


export default settings