import { render, staticRenderFns } from "./downloadExcel.vue?vue&type=template&id=5a33b639&scoped=true&"
import script from "./downloadExcel.ts?vue&type=script&lang=ts&"
export * from "./downloadExcel.ts?vue&type=script&lang=ts&"
import style0 from "./downloadExcel.less?vue&type=style&index=0&id=5a33b639&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a33b639",
  null
  
)

export default component.exports