import settings from "@/settings";

export function GetImageThumbPath(id: number) {
    return `${settings.inspectAnalyzerServiceUrl}/api/image/${id}/thumb`;
}

export function GetImageResize(id: number,
                               width:number,
                               height:number,
                               cropTop?:number ,
                               cropLeft?:number,
                               cropWidth?:number,
                               cropHeight?:number,
                               crop:boolean = false,
                               ) {
    // console.log("cropTop: " + cropTop + ", cropLeft: " + cropLeft  + " ,cropWidth: " + cropWidth + " ,cropHeight: " + cropHeight);

    // console.log(`${settings.inspectAnalyzerServiceUrl}/api/image/${id}/resize?width=${width}&height=${height}&crop=${crop}&cropTop=${cropTop}&cropLeft=${cropLeft}&cropWidth=${cropWidth}&cropHeight=${cropHeight}`);

    return `${settings.inspectAnalyzerServiceUrl}/api/image/${id}/resize?width=${width}&height=${height}&crop=${crop}&cropTop=${cropTop}&cropLeft=${cropLeft}&cropWidth=${cropWidth}&cropHeight=${cropHeight}`;
}