import { render, staticRenderFns } from "./displayLabel.vue?vue&type=template&id=471bd7f6&scoped=true&"
import script from "./displayLabel.ts?vue&type=script&lang=ts&"
export * from "./displayLabel.ts?vue&type=script&lang=ts&"
import style0 from "./displayLabel.less?vue&type=style&index=0&id=471bd7f6&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "471bd7f6",
  null
  
)

export default component.exports