import { Vue, Component } from 'vue-property-decorator';
import ModalContainer from './modals/modalContainer.vue';
import TerritorialDistrict from './views/territorialDistrict/territorialDistrict.vue';
import Sidebar from './components/sidebar/sidebar.vue';
import Headerbar from './components/headerbar/headerbar.vue';
import { store } from './store';
import { metadataServices } from './services/metadata.services';
import { MessageService } from 'vue-mf-module';
import { loginServices } from './services/login.service';

@Component({
    components: {
        ModalContainer,
        TerritorialDistrict,
        Sidebar,
        Headerbar,
    }
})
export default class App extends Vue {

    async mounted() {
        MessageService.Instance.subscribe("ROUTE_TO", (page) => {
            this.$router.push(page);
        });

        var token = await loginServices.GetAccessToken();
        if (!token)
            this.$router.push("/login");

        await metadataServices.getLines(null, null, 0, 500);
        await metadataServices.getComponents();
        await metadataServices.getIssueTypes();
        await metadataServices.getSearchs();
    }

    get territorialDistrictOpen() {
        return store.getters.territorialDistrict.getTerritorialDistrictOpen()
    }

}