import { Vue, Component, Prop } from "vue-property-decorator";
import Map from "@/components/map/map.vue";
import ImageSearch from "@/components/imageSearch/imageSearch.vue";
import ImageAnalytics from "@/components/imageAnalytics/imageAnalytics.vue";
import { ModalHelper } from "@/modals/ModalHelper";
import ImageInspect from "../imageInspect/imageInspect.vue";
import { store } from "@/store";
import { metadataServices } from "@/services/metadata.services";

@Component({
    components: {
        mapItem: Map,
        ImageSearch,
        ImageAnalytics
    }
})
export default class MapHome extends Vue {

    @Prop()
    mission: string

    @Prop({ default: "" })
    image: string

    active_tab: "research" | "analytics" = "research"
    imageIds: string[] = []
    objectsSelected: inspect.ImageModel[] = []
    highlightedImage: string = ""

    get showMission(): boolean {
        return store.getters.search.getShowMission()
    }

    get objectsSelectedIds() {
        return this.objectsSelected.map(obj => Number.parseInt(obj.name))
    }

    get imageList() {
        return this.showMission ? store.getters.images.getMission(this.mission).images : store.getters.images.getImageList();
    }

    async created() {
        if (!this.imageList || this.imageList.length == 0) this.$router.push({ name: "home" })
        if (this.image != "") {
            let image: inspect.ImageModel = JSON.parse(this.image);

            let imageNote = store.getters.images.getImageNote(image.id)
            let note = imageNote != undefined && imageNote != null ?
                JSON.parse(JSON.stringify(imageNote)) :
                {
                    imageId: image.id,
                    erroreAnomalia: false,
                    nuovaAnomalia: false,
                    note: ""
                }


            ModalHelper.push({
                title: `Dettagli immagine`,
                content: ImageInspect,
                id: "image_inspect",
                closeButton: true,
                hideFooter: false,
                model: {
                    images: this.imageList,
                    imageId: image.id,
                    note: note
                },
                buttons: [
                    { class: "second", id: "back_to_research", title: "Torna alla ricerca" },
                    { class: "second", id: "back_to_map", title: "Torna alla mappa" },
                    { id: "confirm", title: "Invia note" }
                ],
                callback: async (id, mod) => {
                    if (id === "confirm") {
                        await metadataServices.saveNote(mod.note)
                    }
                    if (id === "back_to_research") {
                        this.$router.push({ name: "home" })
                    }
                    return true;
                }
            })
        }
        this.imageIds = this.imageList.map(img => img.id.toString())
    }

    filteredImagesListChanged(value: inspect.ImageModel[]) {
        this.objectsSelected = []
        this.imageIds = value.map(img => img.id.toString())
    }

    highlight(value: string) {
        this.highlightedImage = value
    }

    onExposurePointClick(value: inspect.ImageModel[]) {
        this.objectsSelected = value
    }
}