import Login from '@/components/login/Login.vue';
import { CONFIGURATION } from '@/Configuration';
import type { AuthToken } from '@/services/_base/baseRestService';
import { Component, Vue } from 'vue-property-decorator';


@Component({
  components: {
    Login
  }
})
export default class LoginPage extends Vue {
  authToken: AuthToken | null = null;

  get configuration() {
    return CONFIGURATION;
  }

  authenticated(authToken: AuthToken) {
    this.authToken = authToken;
  }

  get isSignInActive(): boolean {
    return CONFIGURATION.auth.LocalRegistrationEnabled;
  }

  mounted() {
    this.appendStyle();
  }

  appendStyle() {
    let style = document.createElement("style");
    style.innerHTML = `.loginpage {
      background-image: ${CONFIGURATION.backgroundImage ? `url(${CONFIGURATION.backgroundImage}) !important` : 'var(--login-background-image)'};
    }
    .logo-container {
      background-image: ${CONFIGURATION.logoImage ? `url(${CONFIGURATION.logoImage}) !important` : `var(--login-wisetown-logo)`};
    }
    .cobranding-container{
      background-image: ${CONFIGURATION.cobrandingImage ? `url(${CONFIGURATION.cobrandingImage}) !important` : `undefined`};
    }`
    this.$el.appendChild(style);
  }

  goToHome() {
    this.$router.push("/");
  }

  gotoRegistration() {
    this.$router.push({ name: "mailRegistration" });
  }

  gotoLogin() {
    this.$router.push({ name: "mailLogin" });
  }
}
