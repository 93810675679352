import Vue from "vue"
import Component from "vue-class-component";
import { store } from "@/store";
import MapSearch from "@/components/mapSearch/mapSearch.vue";
import Extent from "@arcgis/core/geometry/Extent.js";
import Geometry from "@arcgis/core/geometry/Geometry.js";
import * as webMercatorUtils from "@arcgis/core/geometry/support/webMercatorUtils.js";

@Component({
    components: {
        MapSearch
    }
})
export default class TerritorialDistrict extends Vue {

    territorialDistrictList: string[] = []
    toRefresh: boolean = false
    onSelection: boolean = false
    selectionToConfirm : boolean = false
    longlatextent:inspect.LongLatExtent = null;

    get territorialDistrictOpen() {
        return store.getters.territorialDistrict.getTerritorialDistrictOpen()
    }

    close() {
        store.actions.territorialDistrict.setTerritorialDistrictOpen(false)
        this.selectionToConfirm = false
    }

    refresh() {
        this.toRefresh = true
        this.selectionToConfirm = false
    }

    confirm() {
        //TOLTO IDS
        //store.actions.territorialDistrict.setTerritorialDistrictList(this.territorialDistrictList);
        store.actions.territorialDistrict.setTerritorialDistrictOpen(false);
        store.actions.territorialDistrict.setTerritorialDistrictExtent(this.longlatextent);
        this.selectionToConfirm = false;
    }

    onSelectedFeatureChanged(value: any[]) {
        if(value.length == 0) this.selectionToConfirm = false
        else this.territorialDistrictList = value.map(v => v.attributes.name)
    }

    onSelectedGeometryChanged(geometry: Geometry){
        if(geometry){
            this.onSelection = false;
            this.selectionToConfirm = true;

            const latlongmax = webMercatorUtils.xyToLngLat(geometry.extent.xmax,geometry.extent.ymax);
            const latlongmin = webMercatorUtils.xyToLngLat(geometry.extent.xmin,geometry.extent.ymin);

            this.longlatextent = {
                longmax : latlongmax[0],
                latmax : latlongmax[1],
                longmin: latlongmin[0],
                latmin : latlongmin[1],
            } as inspect.LongLatExtent;
        }
    }

}