import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import 'vue-multiselect/dist/vue-multiselect.min.css';
import Multiselect from 'vue-multiselect'
Vue.component("multiselect", Multiselect)

import * as filters from '@/filters';
// Register global filter functions
Object.keys(filters).forEach(key => {
  Vue.filter(key, (filters as { [key: string]: Function, })[key]);
});

Vue.mixin({
  data: function () {
    return {
      __permissions: []
    }
  },
  // data: {
  //   __permissions: []
  // },
  methods: {
    $t: function(key: string, defaultString: string = null) {
      return  defaultString || key;
    }
  }
});

import * as components from '@/components/global';
// Register global components functions
Object.keys(components).forEach(key => {
  Vue.component(key, components[key])
});


MessageService.Instance.subscribe("ASK_LOGIN", async () => {
  MessageService.Instance.send('ROUTE_TO', "/login");
});


MessageService.Instance.reply("ACCESS_TOKEN", () => loginServices.GetAccessToken());
// MessageService.Instance.reply("WHO_AM_I", async () =>  await usersService.whoAmI());
// MessageService.Instance.reply("AM_I_LOGGEDIN", async () => !!(await usersService.whoAmI()));

// router.beforeEach(async (to, from, next) => {
//   let permissions = store.getters.ui.getPermissions();

//   // Controllo se ho i permessi..
//   if (to.meta && to.meta.claims && permissions && permissions.length) {
//     if (!(to.meta.claims as string[]).some(i => permissions.includes(i))) {
//       // Non ho i permessi per la rotta richiesta.
//       next('/login');
//       return;
//     }
//   }

//   // Se la pagina richiede login e non sono loggato.. 
//   if (to.meta?.requireAuth && !await MessageService.Instance.ask('AM_I_LOGGEDIN')) {
//     next('/login');
//     return;
//   }
//   next();
// });

import { loadSettings } from "./settings";
import { MessageService } from 'vue-mf-module'
import { loginServices } from './services/login.service'

loadSettings().then( async()=>{
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
});


