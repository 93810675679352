/* eslint-disable */

import moment from "moment";

// Set utils function parseTime to filter
export function formatTime(value: any, option?: string): string {
  if (!value) {
    return null;
  }

  let time: any = value;
  if (typeof (value) === 'string') {
    time = new Date(value);
  }

  if (!time.getTime) {
    return null;
  }

  const format = option || 'DD/MM/YYYY HH:mm:ss';
  let date;
  if (typeof time === 'object') {
    date = time;
  } else {
    if (('' + time).length === 10) time = parseInt(time) * 1000;
    date = new Date(time);
  }
  return moment(date).format(format);
};

export function formatValue(value: any, c = 2, suffix?: string, prefix?: string, d?: string, t?: string) {
  if (value === null || typeof value === 'undefined') return null;
  var d = d === undefined || d === null ? ',' : d;
  var t = t === undefined || d === null ? '.' : t;
  const s = value < 0 ? '-' : '';
  const i = String(parseInt((value = Math.abs(Number(value) || 0).toFixed(c))));
  var j = (j = i.length) > 3 ? j % 3 : 0;
  const v =
    (prefix || '') +
    s +
    (j ? i.substr(0, j) + t : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) +
    (c > 0
      ? d +
      Math.abs(value - Number(i))
        .toFixed(c)
        .slice(2)
      : '') +
    (suffix || '');
  return v;
}

// Filter to uppercase the first character
export const uppercaseFirstChar = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const stripHTML = (html: string) => {
  const elem = document.createElement('div')
  elem.innerHTML = html;
  return elem.textContent || elem.innerText || ''
}

export const ConvertDDToDMS = function (decimal: number | string) {
  let D: number;
  if (typeof (decimal) === "string")
    D = parseFloat(decimal);
  else
    D = decimal;

  return [0 | D, '°', 0 | (D = (D < 0 ? -D : D) + 1e-4) % 1 * 60, "'", 0 | D * 60 % 1 * 60, '"'].join('');
}

export function FormatMissionName(mission:string){
  const res = "";

  if(mission && mission.indexOf("/")){
    return mission.split("/").pop();
  }

  return res;
}

