import { metadataServices } from "@/services/metadata.services";
import { store } from "@/store";
import Vue from "vue"
import Component from "vue-class-component";

@Component({
    components: {}
})
export default class SavedSearch extends Vue {

    tooltipValues: string[] = []

    get savedSearchList() {
        return store.getters.search.getSearchList()
    }

    get currentSearch() {
        return store.getters.search.getCurrentSearch()
    }

    get currentSearchData() {
        return store.getters.search.getCurrentSearchData()
    }

    setIssueTooltipValues(issues: inspect.IssueType[]) {
        this.tooltipValues = issues.map(i => i.anomalia)
    }

    setSearch(search: inspect.DeserializedStoredSearch) {
        this.$router.push({ name: "home", params: { current_search: JSON.stringify(search) } })
    }

    formatDate(from: Date, to: Date) {
        let formattedDate: string = "-"
        let toDate = new Date(to).toLocaleString("IT-it").split(',')[0]
        let fromDate = new Date(from).toLocaleString("IT-it").split(',')[0]

        if (from != null) to != null ?
            formattedDate = `${fromDate} - ${toDate}` :
            formattedDate = `${fromDate} - ${toDate}`
        else if (from != null)
            formattedDate = `${toDate} - ${toDate}`

        return formattedDate
    }

    async deleteSearch(search: inspect.DeserializedStoredSearch) {
        if (this.currentSearch != null && search.id == this.currentSearch.id) {
            store.actions.search.setCurrentSearch(null);
        }
        if (search && search.id > 0) {
            await metadataServices.deleteSearch(search);
        }
    }

} 