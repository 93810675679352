import { render, staticRenderFns } from "./imageInspect.vue?vue&type=template&id=513cee89&scoped=true&"
import script from "./imageInspect.ts?vue&type=script&lang=ts&"
export * from "./imageInspect.ts?vue&type=script&lang=ts&"
import style0 from "./imageInspect.less?vue&type=style&index=0&id=513cee89&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "513cee89",
  null
  
)

export default component.exports