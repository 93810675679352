
import { Vue, Component } from "vue-property-decorator";
import { store } from "@/store";
import { Prop, Watch } from "vue-property-decorator";

@Component({})
export default class DisplayLabel extends Vue {

    @Prop({ required: true })
    public labels: inspect.Issue[];

    @Prop()
    maxHeight: number;

    @Prop()
    maxWidth: number;

    @Prop()
    boxTop: number;

    @Prop()
    boxLeft: number;

    @Prop()
    highligthedIssue : inspect.Issue;

    @Prop({ default: false })
    disableClick: number;
    
    imageViewWidth: number = 0;
    imageViewHeight: number = 0;
    hidden:boolean=false;

    mounted() {
        this.hidden = false;
        this.imageViewWidth = (this.$refs.dispalylabelcontainer as HTMLDivElement).clientWidth;
        this.imageViewHeight = (this.$refs.dispalylabelcontainer as HTMLDivElement).clientHeight;
    }

    heightValue(height: number) {
        return (height * this.imageViewHeight) / this.maxHeight;
    }

    widthValue(width: number) {
        return (width * this.imageViewWidth) / this.maxWidth;
    }

    topValue(top: number) {
        if(this.boxTop != null) top -= this.boxTop 
        return (top * this.imageViewHeight) / this.maxHeight ;
    }

    leftValue(left: number) {
        if(this.boxLeft != null) left -= this.boxLeft 
        return (left * this.imageViewWidth) / this.maxWidth;
    }

    async selectIssue(issue){
        this.hidden = true;
        if(!this.disableClick)
            this.$emit("onClickedIssue",issue,false);
    }
}