export interface DomainStoreModel {
    lines: inspect.Line[];
    components: inspect.Component[];
    issues: inspect.IssueType[];
    assets: inspect.AssetType[];
    issueColors: { [issue: string]: string }
}

export interface DomainStoreActions {
    setLines(model: inspect.Line[]);
    setComponents(model: inspect.Component[]);
    setIssues(model: inspect.IssueType[]);
    setAssets(model: inspect.AssetType[]);
    setIssueColors(model: { issue: string, color: string }[]);
    setIssueRandomColors(model: string[]);
}

export interface DomainStoreGetters {
    getLines(): inspect.Line[];
    getComponents(): inspect.Component[];
    getIssues(): inspect.IssueType[];
    getAssets(): inspect.AssetType[];
    getIssueColor(issue: string): string;
    getIssueColors(): { [issue: string]: string };
}

export const DomainStore = {
    PREFIX: "domain",
    namespaced: true,
    state: {
        lines: [],
        components: [],
        issues: [],
        assets: [
            {
                id: 0,
                asset: "Sostegni"
            },
            {
                id: 1,
                asset: "Campate"
            }
        ],
        issueColors: {}
    },
    actions: {
        setComponents(context, model: inspect.Component[]) {
            context.commit("setComponents", model)
        },
        setIssues(context, model: inspect.IssueType[]) {
            context.commit("setIssues", model)
        },
        setAssets(context, model: inspect.AssetType[]) {
            context.commit("setAssets", model)
        },
        setLines(context, model: inspect.Line[]) {
            context.commit("setLines", model)
        },
        setIssueColors(context, model: { issue: string, color: string }[]) {
            context.commit("setIssueColors", model)
        },
        setIssueRandomColors(context, model: string[]) {
            context.commit("setIssueRandomColors", model)
        },
    },
    mutations: {
        setComponents(state: DomainStoreModel, model: inspect.Component[]) {
            state.components = model
        },
        setIssues(state: DomainStoreModel, model: inspect.IssueType[]) {
            state.issues = model
        },
        setAssets(state: DomainStoreModel, model: inspect.AssetType[]) {
            state.assets = model
        },
        setLines(state: DomainStoreModel, model: inspect.Line[]) {
            state.lines = model
        },
        setIssueColors(state: DomainStoreModel, model: { issue: string, color: string }[]) {
            model.forEach(m => {
                state.issueColors[m.issue] = m.color;
            })
        },
        setIssueRandomColors(state: DomainStoreModel, model: string[]) {
            model.forEach(m => {
                state.issueColors[m] = '#'+Math.floor(Math.random()*16777215).toString(16);
            })
        },
    },
    getters: {
        getComponents: state => (): inspect.Component[] => state.components,
        getIssues: state => (): inspect.IssueType[] => state.issues,
        getAssets: state => (): inspect.AssetType[] => state.assets,
        getLines: state => (): inspect.Line[] => state.lines,
        getIssueColor: state => (issue:string): string => state.issueColors[issue],
        getIssueColors: state => ():{ [issue:string]: string }=> state.issueColors,
    }
}