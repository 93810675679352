
export interface TerritorialDistrictStoreModel {
    territorialDistrictOpen: boolean,
    territorialDistrictList: number[],
    territorialDistrictExtent:inspect.LongLatExtent,
}

export interface TerritorialDistrictStoreActions {
    setTerritorialDistrictOpen(model: boolean),
    // setTerritorialDistrictList(model: string[]),
    setTerritorialDistrictExtent(model: inspect.LongLatExtent),
}

export interface TerritorialDistrictStoreGetters {
    getTerritorialDistrictOpen(): boolean;
    // getTerritorialDistrictList(): number[];
    getTerritorialDistrictExtent(): inspect.LongLatExtent;
}

export const TerritorialDistrictStore = {
    PREFIX: "territorialDistrict",
    namespaced: true,
    state: {
        territorialDistrictOpen: false,
        territorialDistrictList: [],
        territorialDistrictExtent:null,
    },
    actions: {
        setTerritorialDistrictOpen(context, model: boolean) {
            context.commit("setTerritorialDistrictOpen", model)
        },
        // setTerritorialDistrictList(context, model: string[]) {
        //     context.commit("setTerritorialDistrictList", model)
        // },
        setTerritorialDistrictExtent(context, model: inspect.LongLatExtent) {
            context.commit("setTerritorialDistrictExtent", model)
        },
    },
    mutations: {
        setTerritorialDistrictExtent(state: TerritorialDistrictStoreModel, model: inspect.LongLatExtent) {
            state.territorialDistrictExtent = model;
        },
        setTerritorialDistrictOpen(state: TerritorialDistrictStoreModel, model: boolean) {
            state.territorialDistrictOpen = model;
        },
        // setTerritorialDistrictList(state: TerritorialDistrictStoreModel, model: string[]) {
        //     state.territorialDistrictList = [];
        //     if(model && model.length>0){
        //         let tmp=[];
        //         model.forEach(element => {
        //             tmp.push(parseInt(element));
        //         }); 
        //         state.territorialDistrictList = tmp;
        //     }
           
        // },
    },
    getters: {
        getTerritorialDistrictOpen: state => (): boolean => state.territorialDistrictOpen,
        // getTerritorialDistrictList: state => (): number[] => state.territorialDistrictList,
        getTerritorialDistrictExtent: state => (): inspect.LongLatExtent[] => state.territorialDistrictExtent,
    }
}